<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="2" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}"></right-tool>
		<article class="wrapper">
			<div class="top">
				<div class="banner flex-row" id="banner">
					<div class="pic">
						<img class="animated picscall" src="../../assets/images/movies_banner.png" >
					</div>
					<div class="ind_hint_wrap flex-col">
						<div class="ind_hint_icon animate__animated animate__fadeInUp" style="animation-delay: .2s;"><div></div></div>
						<div class="ind_hint ft-12 animate__animated animate__fadeInUp" style="animation-delay: .4s;">SCROLL DOWN</div>
					</div>
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-64 animate__animated animate__fadeInDown" style="animation-delay: .2s;">
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0s;">让</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.1s;">创</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.2s;">造</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.3s;">力</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.4s;">随</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.5s;">影</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.6s;">而</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="animation-delay: 0.7s;">生</em>
								</span>
							</h1>
							<small class="ft-20 animate__animated animate__fadeInUp" style="animation-delay: .6s;">
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0s;">Let</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.2s;">creativity</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.4s;">be</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.6s;">born</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: 0.7s;">with</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: .8s;">the</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="animation-delay: .9s;">film</em>
								</span>
							</small>
						</div>
					</div>
				</div>
			</div>
			<div id="main" ref="main">
				<div id="main-wrap" class="main-wrap" ref="moviesWrap">
					<div class="screen-wrap flex-row">
						<div class="classify-wrap flex-row ft-14">
							<span class="animate__animated">分类</span>
							<ul class="ul flex-row animate__animated">
								<li :class="{'active': index === classifyIndex}" v-for="(item,index) in classifyList" :key="index" @click="classifyTap(item,index)">
									<a>{{item.text}}</a>
								</li>
							</ul>
						</div>
						<div class="status-wrap flex-row ft-14">
							<span class="animate__animated">状态</span>
							<ul class="ul flex-row animate__animated">
								<li :class="{'active': index === statusIndex}" v-for="(item,index) in statusList" :key="index" @click="statusTap(item,index)">
									<a>{{item.text}}</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="moviesList" v-if="moviesList.length">
						<div class="movies-item flex-row animate__animated" v-for="(item,index) in moviesList" :key="index" @click="detail(item)">
							<div class="img-wrap">
								<div class="label started ft-14" v-if="item.startStatus === 'preheating'">预热中</div>
								<div class="label make ft-14" v-if="item.startStatus === 'handing'">进行中</div>
								<div class="label make ft-14" v-if="item.startStatus === 'released'">已上映</div>
								<!-- <div class="label make ft-14">后期制作</div> -->
								<img :src="item.coverUrl" />
								<!-- <div class="status ft-14">{{item.startStatus_dictText}}</div> -->
							</div>
							<div class="movies-content flex-col">
								<div class="movies-name">
									<h1 class="ft-30 two-ellipsis">{{item.name}}</h1>
								</div>
								<div class="movies-info flex-col ft-12">
									<p v-if="item.director">导演：{{item.director}}</p>
									<p v-if="item.screenwriter">编剧：{{item.screenwriter}}</p>
									<p v-if="item.toStar">主演： {{item.toStar}}</p>
									<p v-if="item.type_dictText">类型：{{item.type_dictText}}</p>
									<p v-if="item.producer">出品方：{{item.producer}} </p>
								</div>
								<!-- <div class="movies-detail ft-16 three-ellipsis">
									{{item.information}}
								</div> -->
								<div class="applyBtn flex-row ft-14" v-if="item.startStatus !== 'released'" @click.stop="applyTap(item)">
									<span>申请份额</span>
									<img src="../../assets/images/top_icon.png" />
								</div>
							</div>
						</div>
					</div>
					<div class="empty-wrap flex-row" v-if="!moviesList.length">
						<img v-if="!moviesList.length && !isLoading" src="../../assets/images/empty_tabel.png" />
					</div>
				</div>
			</div>
		</article>
	</div>
</template>

<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import { moviesStatus,moviesType,moviesList,shareApply } from '@/api/api.js'
	import gsap from 'gsap'
	import { TweenMax,Power2 } from 'gsap'
	gsap.registerPlugin(TweenMax)
	gsap.registerPlugin(Power2)
	import $ from 'jquery'
	export default{
		name: 'movies',
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				showMenu: false,
				func: null,
				minBar: false,
				classifyIndex: 0,
				statusIndex: 0,
				scTop: 0,
				btnFlag: false,
				isLoading: false,
				startStatus: '',
				type: '',
				pageNo: 1,
				pageSize: 5,
				isLoadMore: false,
				classifyList: [],
				statusList: [],
				moviesList: [],
			}
		},
		beforeRouteLeave(to, from, next) {
			if (to.path === '/moviesDetail') {
				if (!this.$store.state.user.cacheList.includes('movies')) {
					this.$store.state.user.cacheList.push('movies')
				}
			}
			next();
		},
		created() {
		},
		mounted() {
			TweenMax.defaultOverwrite="all"
			window.addEventListener("beforeunload", (function(){window.scrollTo(0,0);}));
			this.getMoviesType()
		},
		// 在离开页面时候会调用keep-alive钩子函数把数据保存到本地
		deactivated () {
			
		},
		// keepalive缓存了数据 当再次进入这样页面时候会走activated 构造函数
		activated () {
			document.body.style.cssText =`height:${$(window).height() + $('.main-wrap')[0].offsetHeight}px;background-color: #111111;`
			this.$refs.moviesWrap.scrollTop = localStorage.getItem('movTop')
		},
		beforeDestroy() {
			window.removeEventListener('scroll',this.func)
		},
		destroyed() {
			document.body.style.cssText = "height: 100%;"
		},
		methods:{
			changeMenu(bool) {
				this.showMenu = !bool
			},
			classifyTap(item,index) {
				this.classifyIndex = index
				this.type = item.value
				this.clearData()
				this.getMoviesList()
			},
			statusTap(item,index) {
				this.statusIndex = index
				this.startStatus = item.value
				this.clearData()
				this.getMoviesList()
			},
			clearData() {
				this.pageNo = 1
				this.moviesList = []
				this.isLoadMore = false
			},
			getMoviesType() {
				moviesType({}).then(res =>{
					if (res.success) {
						res.result.unshift({text: "全部",title: "全部",value: ""})
						this.classifyList = res.result
						this.getMoviesStatus()
					}
				})
			},
			getMoviesStatus() {
				moviesStatus({}).then(res =>{
					if (res.success) {
						res.result.unshift({text: "全部",title: "全部",value: ""})
						this.statusList = res.result
						this.getMoviesList()
					}
				})
			},
			getMoviesList() {
				let data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					startStatus: this.startStatus,
					type: this.type
				}
				this.isLoading = true
				moviesList(data).then(res =>{
					if (res.success) {
						this.moviesList = this.moviesList.concat(res.result.records)
						if (res.result.records.length < this.pageSize) {
							this.isLoadMore = true
						} else {
							this.pageNo++
							this.isLoadMore = false
						}
					} else {
						this.isLoadMore = true
					}
					this.isLoading = false
					setTimeout(() =>{
						// 数据加载完重新计算高度
						document.body.style.cssText =`height:${$(window).height() + $('.main-wrap')[0].offsetHeight}px;background-color: #111111;`
						if (this.$route.query.scrollMain) {
							document.querySelector("#main-wrap").scrollIntoView(true)		
						}
						this.fn()
					},100)
					if (this.pageNo === 2) {
						this.func = this.throttle(this.handleScroll,1,500)
						window.addEventListener('scroll',this.func)
					}
				})
			},
			// 节流
			throttle(func, wait, mustRun) {
				var timeout,
					startTime = new Date();
				return function() {
					var context = this,
						args = arguments,
						curTime = new Date();
					clearTimeout(timeout);
					// 如果达到了规定的触发时间间隔，触发 handler
					if(curTime - startTime >= mustRun){
						func.apply(context,args);
						startTime = curTime;
					// 没达到触发间隔，重新设定定时器
					}else{
						timeout = setTimeout(func, wait);
					}
				};
			},
			// 防抖
			debounce(func, wait) {
				let timeout;
				return function () {
					let context = this;
					let args = arguments;
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						timeout = undefined
						func.apply(context, args)
					}, wait || 0);
				}
			},
			handleScroll(){
				$('body').addClass('disable-hover')
				this.throttle(()=>{
					$('body').removeClass('disable-hover')
				}, 300, 1000)()
				this.fn()
			},
			fn() {
				var scroH = $(document).scrollTop();  //滚动高度
				var viewH = $(window).height();  //可见高度
				var contentH = $(document).height();  //内容高度
				if(scroH > 650){  //距离顶部大于100px时
					this.minBar = true
					this.btnFlag = true
				}
				if (scroH < 500) {
					this.minBar = false
					this.btnFlag = false
				}
				if (contentH - (scroH + viewH) <= 100){  //距离底部高度小于100px
					// console.log('距离底部高度小于100px')
					if(!this.isLoadMore) {
						this.isLoadMore = true
						this.getMoviesList()
					}
				} 
				if (contentH == (scroH + viewH)){  //滚动条滑到底部啦
					// console.log('滚动条滑到底部啦')
				}
				this.scTop = $(document).scrollTop()
				TweenMax.to('#banner', .7, {x:0, y:-scroH * 0.3, z:0,ease:Power2.easeOut,force3D:true});
				TweenMax.to('#main', .7, {width: '100%',position:'fixed',x:0, y:-scroH, z:0,ease:Power2.easeOut,force3D:true});
				this.setAnimate('.screen-wrap span','animate__fadeInDown')
				this.setAnimate('.ul','animate__fadeInDown')
				this.setAnimate('.movies-item','animate__fadeInUp')
			},
			setAnimate(dom,animateName) {
				if ($(dom).length === 1 ) {
					if ($(dom).hasClass('animate__fadeInUp')) {
						return
					}
					let domHeight = $(window).height() - ($(dom).offset().top - $(document).scrollTop()) - $(dom).height()
					if (($(window).height() - domHeight) > $(window).height() * 0.5) {
						$(dom).css("visibility","visible");
						$(dom).addClass(animateName)
					}					
				} else {
					var domList = $(dom)
					let domHeight = 0
					for(let i = 0; i < domList.length; i++) {
						if ($(domList[i]).hasClass('animate__fadeInUp')) {
							continue
						} else {
							domHeight = $(window).height() - ($(domList[i]).offset().top - $(document).scrollTop()) - $(domList[i]).height()
							if (($(window).height() + domHeight) > $(window).height() * 0.5) {
								$(domList[i]).css({"visibility":"visible","animation-delay": "0."+i+"s"});
								$(domList[i]).addClass(animateName)
							}							
						}
					}
				}
			},
			applyTap(item) {
				if (this.$store.state.user.isLogin) {
					shareApply({moviesId: item.id}).then(res => {
						if (res.success) {
							this.$refs.header.regSuccess({title: '份额申请成功！请等待工作人员与您联系！'})
						} else {
							this.$refs.header.showError({title: res.message})
						}
					})
				} else {
					this.$refs.header.login()
				}
			},
			detail(item) {
				localStorage.setItem('movTop', this.scTop)
				this.$router.push({path:'/moviesDetail',query:{id:item.id}})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		nav.on{
			padding: 0.5% 2%;
			background: rgba(0,0,0,.6);
			box-shadow: 0 7px 32px -12px rgba(0,0,0,.9);
		}
		.wrapper{
			.top{
				position: fixed;
				width: 100%;
				overflow: hidden;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 4;
				min-height: 100vh;
				.banner{
					align-content: space-around;
					flex-wrap: wrap;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-color: #000;
					.pic{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							display: block;
							transition: all 3s ease;
						}
					}
					.ind_hint_wrap{
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 30px 0;
						width: 100%;
						.ind_hint{
							color: #FFFFFF;
						}
						.ind_hint_icon{
							position: relative;
							width: 16px;
							height: 26px;
							border: 2px solid #fff;
							border-radius: 8px;
							padding: 4px 0;
							margin-bottom: 10px;
							div{
								height: 6px;
								width: 2px;
								background-color: #fff;
								border-radius: 2px;
								position: absolute;
								top: 6px;
								left: 50%;
								margin-left: -1px;
								animation: gun0 1s ease infinite;
							}
						}
					}
					.topic {
						position: relative;
						z-index: 10;
						width: 100%;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						.txt_ {
							height: 130px;
							min-width: 50vw;
							margin-bottom: 2vh;
							h1 {
								color: #fff;
								line-height: 60px;
								letter-spacing: 4px;
								span > em {
									font-weight: 400;
								}
							}
							small {
								display: block;
								color: #fff;
								line-height: 40px;
								height: 40px;
								letter-spacing: 1px;
								span {
									margin: 0 4px;
									font-size: inherit;
									color: inherit;
									font-weight: inherit;
									font-family: inherit;
									line-height: 1.2;
									overflow: hidden;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}
			#main{
				padding-top: 100vh;
				z-index: 100;
				pointer-events: none;
				width: 100%;
				min-height: 200vh;
				position: relative;
				overflow: hidden;
				.main-wrap{
					width: 100%;
					background: #111111;
					overflow: hidden;
					min-height: 70vh;
					z-index: 10;
					.screen-wrap{
						width: 72%;
						height: 165px;
						margin: 0 auto;
						pointer-events: auto;
						.classify-wrap,.status-wrap{
							width: 50%;
							padding-left: 20px;
							pointer-events: auto;
							justify-content: flex-start;
							span{
								color: #FFFFFF;
								visibility: hidden;
							}
							.ul{
								padding-left: 20px;
								visibility: hidden;
								li{
									height: 40px;
									padding: 5px 0;
									&.active{
										a{
											border-radius: 50px;
											background-color: #32323C;
											color: #FFFFFF;
										}
									}
									a{
										color: #707070;
										cursor: pointer;
										display: block;
										height: 100%;
										line-height: 30px;
										padding: 0 20px;
										transition: all .3s ease-in-out;
									}
								} 
							}
						}
					}
					.moviesList{
						width: 72%;
						margin: 0 auto;
						pointer-events: auto;
						.movies-item{
							width: 100%;
							margin: 129px 0;
							justify-content: space-between;
							align-items: flex-start;
							color: #FFF;
							visibility: hidden;
							&:first-child{
								margin-top: 0;
							}
							.img-wrap{
								position: relative;
								width: 60vw;
								.label{
									position: absolute;
									top: 14px;
									right: 0;
									height: 26px;
									line-height: 26px;
									padding: 0 10px;
									color: #FFF;
									border-radius: 4px 0 0 4px;
									&.started{
										background: linear-gradient(180deg, #2595CC 0%, #1679DC 100%);
									}
									&.make{
										background-color: #CE9236;
									}
								}
								.status{
									width: 100%;
									position: absolute;
									left: 0;
									bottom: 0;
									height: 74px;
									text-align: right;
									font-weight: bold;
									color: #FF6C00;
									padding: 20px 14px;
									background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
								}
								img{
									display: block;
									width: 100%;
									object-fit: cover;
								}
							}
							.movies-content{
								width: 40vw;
								justify-content: flex-start;
								align-items: flex-start;
								padding: 2vh 0 3vh 3vw;
								// padding-left: 3vw;
								// padding-bottom: 5vh;
								div{
									width: 100%;
									&.movies-name{
										h1{
											line-height: 1.5em;
											max-height: 1.5em;
											overflow: hidden;
										}
									}
									&.movies-info{
										min-height: 14vh;
										line-height: 1.8em;
										justify-content: flex-start;
										align-items: flex-start;
										margin-top: 3vh;
									}
									&.movies-detail{
										line-height: 26px;
									}
									&.applyBtn{
										width: 104px;
										height: 38px;
										padding-left: 4px;
										background: #111111;
										border-radius: 3px;
										border: 1px solid #999999;
										font-weight: bold;
										margin-top: 6vh;
										cursor: pointer;
										img{
											width: 14px;
											height: 9px;
											margin-left: 4px;
											transform: rotate(90deg);
										}
									}
								}
							}
						}
					}
					.empty-wrap{
						min-height: 70vh;
					}
				}
			}
		}
	}
</style>
